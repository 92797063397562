import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { Paragraph } from 'components/styled/typography'
import SubscribeInput from 'components/styled/subscribeInput'

// ASSETS
import Logo from 'svgs/logo_outline.svg'
import Fb from 'svgs/facebook.svg'
import LinkedIn from 'svgs/linkedin.svg'
import Twitter from 'svgs/twitter.svg'
import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  background-color: var(--primary);
  padding: 60px 40px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    padding-bottom: 72px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1520px;
`

const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  flex-wrap: wrap;
  flex: 0 0 100%;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    justify-content: flex-start;
    > a {
      margin: 0 0 calc(var(--spacing) * 8) 0;
      flex: 0 0 50%;
    }
    > div {
      margin: 0 0 calc(var(--spacing) * 8) 0;
      flex: 0 0 100%;
    }
  }
`

const CopyrightText = styled.div`
  margin-left: calc(var(--spacing) * 14);
  color: var(--primary-light);
`

const Link = styled.a`
  cursor: pointer;
  margin-left: calc(var(--spacing) * 14);
  color: var(--primary-light);
  transition: all 0.3s;
  &:hover {
    color: var(--secondary);
  }
`

const SectionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0 0 100%;
  flex-wrap: wrap;
  margin-bottom: calc(var(--spacing) * 12);
  > div {
    color: var(--primary-light);
    flex: 0 0 33%;
    h4 {
      color: var(--white);
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
`

const SocialIcons = styled.div`
  margin-top: 24px;
  cursor: pointer;
  svg {
    margin-left: .5rem;

    path {
      transition: all 0.3s;
    }

    &:hover {
      path {
        fill: var(--secondary);
      }
    }
  }
  > a {
    margin-right: 20px;
  }
`

const StyledParagraph = styled(Paragraph)`
  margin: 8px 0;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin: 16px 0;
  }
`

const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: flex;
    flex-direction: column;
    > div {
      margin-left: 0;
      margin-right: 0;
    }
  }
`

const Footer = () => (
  <Container>
    <FixedContainer>
      <SectionsContainer>
        <div>
          <Logo
            css={`
              margin-bottom: 12px;
            `}
          />
          <Paragraph>
            {getTranslation(
              'footer_line_1',
              'BuildUp is an immersive Bootcamp in',
            )}{' '}
            {getTranslation(
              'footer_line_2',
              'Armenia to support students who would like ',
            )}
            <br />
            {getTranslation(
              'footer_line_3',
              'to accelerate the learning process to',
            )}
            <br />
            {getTranslation('footer_line_4', 'become high-level professionals')}
          </Paragraph>
        </div>
        <ContactsContainer>
          <div>
            <h4>{getTranslation('Contact', 'Contact')}</h4>
            <StyledParagraph>
              <a
                href={`mailto:${getTranslation(
                  'contact_email',
                  'hallo@buildup.com',
                  false,
                )}`}
              >
                {getTranslation('contact_email', 'hallo@buildup.com')}
              </a>
            </StyledParagraph>
            <StyledParagraph>
              <a
                href={`tel:${getTranslation(
                  'contact_phone',
                  '+374492228119',
                  false,
                )}`}
              >
                {getTranslation('contact_phone', '+374 49 2228 119')}
              </a>
            </StyledParagraph>
            <SocialIcons>
              <a
                href={getTranslation('facebook_url', 'facebook_url', false)}
                target="_blank"
              >
                <Fb />
              </a>
              <a
                href={getTranslation('linkedin_url', 'linkedin_url', false)}
                target="_blank"
              >
                <LinkedIn />
              </a>
              {/*
            <a href={getTranslation('youtube_url', 'youtube_url', false)} target='_blank'><Youtube /></a>
            <a href={getTranslation('instagram_url', 'instagram_url', false)} target='_blank'><Instagram /></a>
*/}
              <a
                href={getTranslation('twitter_url', 'twitter_url', false)}
                target="_blank"
              >
                <Twitter />
              </a>
            </SocialIcons>
          </div>
        </ContactsContainer>
        <div>
          <h4>{getTranslation('Newsletter', 'Newsletter')}</h4>
          <SubscribeInput />
        </div>
      </SectionsContainer>
      <LinksContainer>
        <CopyrightText>
          {getTranslation('copyright_text', 'Copyright 2020 BuildUp')}
        </CopyrightText>
        <Link href={'/program'}>
          {getTranslation('The BuildUp Program', 'The BuildUp Program')}
        </Link>
        <Link href={'/terms'}>{getTranslation('Terms', 'Terms')}</Link>
        <Link href={'/privacy'}>{getTranslation('Privacy', 'Privacy')}</Link>
        {/*<Link  href={"/subscription"}>{getTranslation('Become a Trustee','Become a Trustee')}</Link>*/}
        <Link href={'/about'}>{getTranslation('About Us', 'About Us')}</Link>
        <Link href={'/blog'}>{getTranslation('Blog', 'Blog')}</Link>
        <Link href={'/media'}>{getTranslation('Media mentions', 'Media mentions')}</Link>
      </LinksContainer>
    </FixedContainer>
  </Container>
)

export default Footer
