import * as React from 'react'
import styled from 'styled-components'

/**
 * font-weights:
 * bold = 700,
 * medium = 500,
 * regular = 400,
 */

export const H1 = styled.h1`
  color: ${({ inverted }) => (inverted ? 'transparent' : 'var(--primary)')};
  -webkit-text-stroke: 2.7px var(--primary);
  &:hover {
    color: ${({ inverted }) => (inverted ? 'var(--primary)' : 'transparent')};
  }
  @media only screen and (max-width: 769px) {
    -webkit-text-stroke: 1.3px var(--primary);
  }
`

export const Paragraph = styled.p`
  margin: 0;
  > a {
    &:hover {
      color: var(--secondary);
    }
  }
`

const ShadowContainer = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 95%;
    z-index: -1;
    left: -10%;
    background-color: ${({ highlight }) =>
      highlight ? 'var(--secondary)' : 'unset'};
  }
  > * {
    margin: 0;
  }
`

export const Styledlink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`

export const ShadowText = ({ children, highlight }) => (
  <ShadowContainer highlight={highlight} className="mb-100px">
    {/*{children}*/}
    {children}
  </ShadowContainer>
)
